import { ref, Ref } from 'vue';
import Sortable from 'sortable';
import utils from 'o365.modules.utils.js';
import { userSession } from 'o365.modules.configs.ts';

interface SkyColspans {
  counter: number;
  arr: Record<string, number>;
}

interface SkyData {
  days: SkyDay[];
  weeks: SkyWeek[];
  months: SkyMonth[];
  years: SkyYear[];
}

interface SkyDay {
  Date: Date;
  Day: number;
  WeekNo: number;
  Weekday: string;
  Month: number;
  MonthName: string;
  Year: number;
  Workflow: any[];
}

interface SkyWeek {
  Year: number;
  WeekNo: number;
  Date: Date;
  LoadNext: null; 
}

interface SkyMonth {
  Month: number;
  MonthName: string;
}

interface SkyYear {
  Year: number;
}

interface Workflow {
  Item_ID: number;
  OrgUnit: string;
  Title: string;
  Severity: string;
  CreatedBy: string;
  Process: string;
  SeverityColor: string;
  Description: string;
  Step: string;
  UniqueID: string;
  ClosedBy: string;
  StepResp2: string;
  PrimKey: string;
}

const fromDate: Ref<Date> = ref(new Date(new Date().setDate(new Date().getDate() - 30)));
const toDate: Ref<Date> = ref(new Date(new Date().setDate(new Date().getDate() + 270)));
const viewMode: Ref<string> = ref('weeks');
const viewWidth: Ref<number> = ref(300);
const skyColspans: Ref<SkyColspans> = ref({
  year: {
         counter: 0,
    arr: {},
  },
  month: {
    counter: 0,
    arr: {},
  },
  week: {
    counter: 0,
    arr: {},
  },
});
const skyData: Ref<SkyData> = ref({
  days: [],
  weeks: [],
  months: [],
  years: [],
});

const activeWorkflow: Ref<Workflow | null> = ref(null);

const sortables: Sortable[] = [];
function getSortable(element: HTMLElement, options: Sortable.Options): Sortable {
  sortables.push(new Sortable(element, options));
  return sortables[sortables.length - 1];
}

function getDates(startDate: Date, endDate: Date): SkyDay[] {
  const dates: SkyDay[] = [];
  let currentDate: Date = startDate;

  const addDays = function (currentDate: Date, days: number): Date {
    const date = new Date(currentDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  while (currentDate <= endDate) {
    dates.push({
      Date: currentDate,
      Day: currentDate.getDate(),
      WeekNo: getWeekNumber(currentDate),
      Weekday: getDayName(currentDate),
      Month: currentDate.getMonth() + 1,
      MonthName: getMonthName(currentDate),
      Year: currentDate.getFullYear(),
      Workflow: [],
    });

    currentDate = addDays(currentDate, 1);
  }

  return dates;
}

function getWeekNumber(d: Date): number {
    var date = new Date(d.getTime());
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    var week1 = new Date(date.getFullYear(), 0, 4);
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

function getMonthName(d: Date): string {
    return userSession.monthNames![d.getMonth()];
}

function getDayName(d: Date): string {
    return userSession.dayNames![d.getDay()];
}

function processData(data: any[]): void {
    skyColspans.value = {
        year: {
            counter: 0,
            arr: {}
        },
        month: {
            counter: 0,
            arr: {}
        },
        week: {
            counter: 0,
            arr: {}
        }
    };

    skyData.value = {
        days: [],
        weeks: [],
        months: [],
        years: [],
    };


    var prevRow = {
        year: null,
        month: null,
        week: null,
        date: '01.01.1900'
    }

    data.forEach((row, index) => {
        if (prevRow.week !== row.WeekNo) {
            if (!prevRow.week) {
                prevRow.week = row.WeekNo;
            }

            skyColspans.value.month.counter++;
            skyColspans.value.year.counter++;
            skyColspans.value.week.arr[prevRow.week] = skyColspans.value.week.counter;

            prevRow.week = row.WeekNo;
            skyColspans.value.week.counter = 0;
            skyData.value.weeks.push({
                Year: row.Year,
                WeekNo: row.WeekNo,
                Date: row.Date,
                LoadNext: null
            });
        }
        if (prevRow.month !== row.Month) {
            if (!prevRow.month) {
                prevRow.month = row.Month;
            }
            skyColspans.value.month.arr[prevRow.month] = skyColspans.value.month.counter;
            prevRow.month = row.Month;
            skyColspans.value.month.counter = 0;
            skyData.value.months.push({
                Month: row.Month,
                MonthName: row.MonthName
            });
        }
        if (prevRow.year !== row.Year) {
            if (!prevRow.year) {
                prevRow.year = row.Year;
            }
            skyColspans.value.year.arr[prevRow.year.toString()] = skyColspans.value.year.counter;
            prevRow.year = row.Year;
            skyColspans.value.year.counter = 0;
            skyData.value.years.push({
                Year: row.Year
            });
        }
        var formatedDate = utils.formatDate(row.Date, "dd.MM.yyyy");
        if (prevRow.date == formatedDate) {
            var day = skyData.value.days.find(x => x.Day == formatedDate);
            if (row.Item_ID) {
                day.Workflows.push(getWorkflowObj(row));
            }
        }
        if (prevRow.date !== formatedDate) {
            prevRow.date = formatedDate;

            skyData.value.days.push({
                Date: row.Date,
                Day: formatedDate,
                OrigDay: row.Day,
                Weekday: row.Weekday,
                Workflows: row.Item_ID ? [getWorkflowObj(row)] : []
            });
        }
        if (data.length - 1 === index) {
            skyColspans.value.year.arr[prevRow.year.toString()] = skyColspans.value.year.counter;
            skyColspans.value.month.arr[prevRow.month] = skyColspans.value.month.counter + 1;
            skyColspans.value.week.arr[prevRow.week] = skyColspans.value.week.counter;
        }
    })
}

function getWorkflowObj(row: any): Workflow {
    return {
        Item_ID: row.Item_ID,
        OrgUnit: row.OrgUnit,
        Title: row.Title,
        Severity: row.Severity,
        CreatedBy: row.CreatedBy,
        Process: row.Process,
        SeverityColor: row.SeverityColor,
        Description: row.Description,
        Step: row.Step,
        UniqueID: row.UniqueID,
        ClosedBy: row.ClosedBy,
        StepResp2: row.StepResp2,
        PrimKey: row.PrimKey
    }
}

export { processData, getSortable, activeWorkflow, fromDate, toDate, getDates, getWeekNumber, viewWidth, viewMode, skyColspans, skyData }